<template>
  <div class="checkbox_item citem_1">
    <label class="checkbox_wrap">
      <input type="radio" :checked="modelValue === value" :value="value" name="checkbox" @change="$emit('update:modelValue', value)" class="checkbox_inp" />
      <span class="checkbox_mark"></span>
      <span class="checkbox_text"><slot></slot></span>
    </label>
  </div>
</template>
    
  <script>
export default {
  props: {
    
    modelValue: {
      type: [String, Number],
      required: true,
    },
    value: { 
      type: [String, Number],
      required: true,
    },
  },
  methods: {},
};
</script>
  
  <style lang="scss" scoped>
.checkbox_item .checkbox_wrap {
  position: relative;
  display: flex;
  align-items: center;

  cursor: pointer;
  width: 110px;
  margin: 0 auto 10px;
  margin-left: 10px;
}



.checkbox_item .checkbox_wrap .checkbox_inp {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
}

.checkbox_text{
    margin-left: 10px;
}

.checkbox_item .checkbox_wrap .checkbox_mark {
  display: inline-block;
  position: relative;
  border-radius: 25px;
}

.checkbox_item .checkbox_wrap .checkbox_mark:before,
.checkbox_item .checkbox_wrap .checkbox_mark:after {
  content: "";
  position: absolute;
  transition: all 0.5s ease;
}

/* basic styles */
.checkbox_item.citem_1 .checkbox_wrap .checkbox_mark {
  background: #ffffff66;
  width: 50px;
  height: 30px;
  padding: 2px;
}

.checkbox_item.citem_1 .checkbox_wrap .checkbox_mark:before {
  top: 3px;
  left: 3px;
  width: 23px;
  height: 23px;
  background: #fff;
  border-radius: 50%;
}

.checkbox_item.citem_1 .checkbox_wrap .checkbox_inp:checked ~ .checkbox_mark {
  background: #34bfa3;
}

.checkbox_item.citem_1
  .checkbox_wrap
  .checkbox_inp:checked
  ~ .checkbox_mark:before {
  left: 24px;
}
</style>
  