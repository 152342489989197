<template>
  <div
    class="select__item card content__main"
    :class="{ active: isActive }"
    @click="toggleClass"
  >
    <h1>{{ project.name.charAt(0) }}</h1>
    <h2>{{ project.name }}</h2>
  </div>
</template>
    
  <script>
export default {
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
        isActive: false,
    };
  },
  methods: {
    toggleClass() {
      this.isActive = !this.isActive;
      this.$emit("select", this.project);
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.card {
  width: 125px;
  padding: 10px;
  margin: 10px 15px;
  transition: all 0.3s;
  &__info {
    &-line {
      text-align: left;
      background: transparent;
      & + .card__info-line {
        margin-top: 10px;
      }
    }
    &-line-total {
      font-weight: 700;
    }
  }
  h1 {
    margin: 0;
    color: rgba(255, 255, 255, 0.15);
    font-size: 3em;
  }
  h2 {
    margin-top: -21px;
    font-size: 21px;
  }
  span {
    width: 100%;
    border: none;
    border-bottom: 1px solid #88888855;
    margin: 15px 0;
    display: block;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
    h1 {
      color: rgba(255, 255, 255, 0.25);
    }
  }
  margin-right: 15px;
  color: rgba(255, 255, 255, 0.85);
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 0.3s;
  &:hover {
    border-color: rgba(255, 255, 255, 0.85);
  }
  h2 {
    text-transform: uppercase;
  }
  &.active {
    background-color: rgba(0, 0, 0, 0.5);
    border-color: rgba(255, 255, 255, 0.85);
    h1 {
      color: rgba(255, 255, 255, 0.25);
      
    }
  }
}
</style>
  